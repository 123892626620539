import React, { useEffect, useState } from "react";
import { TfiAngleDown } from "react-icons/tfi";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { rtlToggle } from "../../store/rtlSetting";

/* eslint-disable */
function Navigation({ className }) {
  const tab = <>&nbsp;&nbsp;</>;
  const [dataHome, setData] = useState("");
  const [dataAbout, setData2] = useState("");
  const [dataServices, setData3] = useState("");
  const [dataServicesG, setData4] = useState("");
  // const [dataServicesD, setData5] = useState("");
  const [dataPortfolio, setData6] = useState("");
  const [dataPortfolioG, setData7] = useState("");
  // const [dataPorfolioD, setData8] = useState("");
  // const [dataPages, setData9] = useState("");
  // const [dataCounter, setData10] = useState("");
  const [dataTeam, setData11] = useState("");
  const [dataSpanish, setData12] = useState("");
  const [dataEnglish, setData13] = useState("");
  const [dataSpa, setData14] = useState("");
  // const [datalinkSpa, setData16] = useState("");
  // const [datalinkSpanish, setData17] = useState("");
  // const [datalinkEnglish, setData18] = useState("");
  // const [dataTestimonials, setData12] = useState("");
  // const [data404, setData13] = useState("");

  useEffect(() => {
    consultEight();
  }, [localStorage.getItem("id_language")]);

  const consultEight = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-navbars/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data.data.attributes.home);
        setData2(data.data.attributes.about);
        setData3(data.data.attributes.services);
        setData4(data.data.attributes.ourServices);
        // setData5(data.data.attributes.servicesDetails);
        setData6(data.data.attributes.portfolio);
        setData7(data.data.attributes.portfolioGeneral);
        // setData8(data.data.attributes.portfolioDetails);
        // setData9(data.data[0].attributes.pages);
        // setData10(data.data[0].attributes.pagesCounters);
        setData11(data.data.attributes.pagesTeam);
        setData12(data.data.attributes.spanish);
        setData13(data.data.attributes.english);
        setData14(data.data.attributes.spa);
        // setData16(data.data.attributes.link_spa);
        // setData17(data.data.attributes.link_spanish);
        // setData18(data.data.attributes.link_english);
        // setData12(data.data[0].attributes.pagesTestimonials);
        // setData13(data.data[0].attributes.pagesError);
      });
  };

  const dispatch = useDispatch();
  // const [count, setCount] = useState(0);
  // const [isDisabled, setDisabled] = useState(false);

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  return (
    <nav className={`restly-header-main-menu fontelection ${className || ""}`}>
      <ul className="main-menu">
        <li>
          <Link to="/">{dataHome}</Link>
        </li>
        <li>
          <Link to="/about">{dataAbout}</Link>
        </li>
        <li>
          <Link to="/service">{dataServicesG}</Link>
        </li>
        <li>
          <Link to="/portfolio">{dataPortfolioG}</Link>
        </li>
        <li>
          <Link to="/team">{dataTeam}</Link>
        </li>
        <li className="language-selector">
          <span>{dataSpa}</span>
          <TfiAngleDown className="dropdown-icon" />
          <ul className="sub-menu">
            <li>
              <button
                onClick={() => {
                  if (localStorage.getItem("id_language") != 1) {
                    dispatch(rtlToggle());
                    localStorage.setItem("id_language", 1);
                  }
                }}
              >
                {dataSpanish}
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  if (localStorage.getItem("id_language") != 2) {
                    dispatch(rtlToggle());
                    localStorage.setItem("id_language", 2);
                  }
                }}
              >
                {dataEnglish}
              </button>
            </li>
          </ul>
        </li>
      </ul>
      <style jsx>{`
        .restly-header-main-menu {
          display: flex;
          justify-content: center;
          padding: 1rem 0;
        }
        .main-menu {
          display: flex;
          list-style-type: none;
          margin: 0;
          padding: 0;
        }
        .main-menu li {
          margin: 0 1rem;
          position: relative;
        }
        .main-menu li a {
          text-decoration: none;
          color: #333;
          font-weight: 500;
          font-size: clamp(14px, 1.5vw, 18px);
        }
        .language-selector {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .dropdown-icon {
          margin-left: 0.5rem;
          transition: transform 0.3s ease;
        }
        .language-selector:hover .dropdown-icon {
          transform: rotate(180deg);
        }
        .sub-menu {
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
          background-color: #fff;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          padding: 0.5rem 0;
          min-width: 120px;
        }
        .language-selector:hover .sub-menu {
          display: block;
        }
        .sub-menu li {
          margin: 0;
          padding: 0.5rem 1rem;
        }
        .sub-menu button {
          background: none;
          border: none;
          cursor: pointer;
          width: 100%;
          text-align: left;
          font-size: clamp(12px, 1.3vw, 16px);
        }
        @media (max-width: 991px) {
          .restly-header-main-menu {
            display: none;
          }
        }
      `}</style>
    </nav>
  );
}

export default Navigation;
