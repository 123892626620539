import React, { useEffect, useState } from "react";
import CounterUp from "../helpers/CounterUp";

export default function TestimonialSection() {
  const [dataCounter1, setData30] = useState("");
  const [dataCounter2, setData31] = useState("");
  const [dataCounter3, setData32] = useState("");
  const [dataTitleAbout6, setData34] = useState("");
  const [dataTitleAbout7, setData35] = useState("");
  const [dataContentAbout3, setData38] = useState("");
  const [dataContentAbout4, setData39] = useState("");
  const [dataContentAbout5, setData40] = useState("");

  useEffect(() => {
    consultTwo();
  }, [localStorage.getItem("id_language")]);

  const consultTwo = () => {
    fetch(
      `${process.env.REACT_APP_API}/ds-pro-abouts/${localStorage.getItem(
        "id_language"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData30(data.data.attributes.counter_about_1);
        setData31(data.data.attributes.counter_about_2);
        setData32(data.data.attributes.counter_about_3);
        setData34(data.data.attributes.title_about_6);
        setData35(data.data.attributes.title_about_7);
        setData38(data.data.attributes.content_about_3);
        setData39(data.data.attributes.content_about_4);
        setData40(data.data.attributes.content_about_5);
      });
  };

  return (
    <section
      className="testimonial-section bg-lighter pt-5 rpt-95 pb-5 rpb-0 containertyle16"
      id="testimonial-section"
    >
      <style>
        {`
          @media (max-width: 991px) {
            .line2 {
              display: none;
            }
          }
        `}
      </style>
      <div className="container">
        <div className="section-title text-center mb-65 text-white fontelection3">
          <h2 className="marginstops11">{dataTitleAbout6}</h2> <br />
          <span>{dataTitleAbout7}</span>
        </div>
        <div className="fact-counter-color text-center mb-30 ">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="success-item wow fadeInUp delay-0-2s">
                <span className="count-text plus">
                  <CounterUp
                    className="text-white "
                    sectionSelect="testimonial-section"
                    endValue={dataCounter1}
                  />
                </span>
                <h4 className="text-white fontelection">{dataContentAbout3}</h4>
              </div>
            </div>
            <div className="line2"></div>
            <div className="col-lg-3 col-md-4 col-sm-6 ">
              <div className="success-item wow fadeInUp delay-0-4s">
                <span className="count-text plus ">
                  <CounterUp
                    sectionSelect="testimonial-section"
                    endValue={dataCounter2}
                  />
                </span>
                <h4 className="text-white fontelection">{dataContentAbout4}</h4>
              </div>
            </div>
            <div className="line2"></div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="success-item wow fadeInUp delay-0-6s">
                <span className="count-text plus">
                  <CounterUp
                    sectionSelect="testimonial-section"
                    endValue={dataCounter3}
                  />
                </span>
                <h4 className="text-white fontelection">{dataContentAbout5}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
