import React from "react";
// import { blogs } from "../../data/blogs.json";
// import { teams } from "../../data/Teams.json";
// import BacktoTopCom from "../helpers/BackToTopCom";
// nimport NewsSection from "../HomeTwo/NewsSection";
import FooterHomeTwo from "../partials/Footers/FooterHomeTwo/index";
import HeaderHomeThree from "../partials/Headers/HeaderHomeThree";
// import AboutSection from "./AboutSection";
// import GallerySection from "./GallerySection";
// import GetInTouchSection from "./GetInTouchSection";
import Hero from "./Hero";
import Hero2 from "./Hero2";
import Hero3 from "./Hero3";
import Hero5 from "./Hero5";
import Hero7 from "./Hero7";
import Hero8 from "./Hero8";
// import PricingSection from "./PricingSection";
import Contactwo from "./Contactwo";
import ServiceSection from "./ServiceSection";
// import SubscribeSection from "./SubscribeSection";
// import TeamSection from "./TeamSection";
// import TestimonialSection from "./TestimonialSection";
import GetInTouchSection from "../AboutPages/AboutOne/GetInTouchSection";
import MainSection from "../AboutPages/AboutSeven/MainSection";

// import BlogSection from "../Blog/BlogSection";
import OurTeamSection from "../HomeFour/OurTeamSection";
import AboutSection from "../HomeOne/AboutSection";
import ServiceSectiontwo from "../HomeOne/ServiceSection";
import MainSectiontwo from "../PortfolioPages/PortfolioThree/MainSectiontwo";
import WorkProcessSectiontwo from "../ServicePages/ServiceOne/WorkProcessSectiontwo";
import TestimonialSection from "./TestimonialSection";
import WorkProcessSection from "./WorkProcessSection";
import WorkProcessSectionCode from "./WorkProcessSectionCode";

export default function HomeThree() {
  let stateMenu = true;
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    stateMenu = false;
  }
  return (
    <>
      <HeaderHomeThree />
      {stateMenu === true ? (
        <>
          <Hero />
          <ServiceSection />
          <WorkProcessSectionCode />
        </>
      ) : (
        <>
          <Hero />
          <ServiceSection />
          <WorkProcessSectionCode />
        </>
      )}

      <WorkProcessSection />
      <Hero2 />
      <MainSection />
      <AboutSection
        className="pb-240 r-com-about-section-two"
        funFact={false}
      />
      <TestimonialSection />
      <GetInTouchSection />

      {
        // <BlogSection className="pb-240 r-com-about-section-two" />
      }

      <Hero3 />
      <ServiceSectiontwo />
      <WorkProcessSectiontwo />
      <Hero5 />
      <MainSectiontwo />
      <Hero7 />
      <OurTeamSection />
      <Hero8 />
      <Contactwo />
      <FooterHomeTwo className="footer-home-three pt-150" />
      {/* <BacktoTopCom /> */}
    </>
  );
}
