import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import logo from "../../../assets/images/logos/logo-three.png";
import { useDispatch } from "react-redux";
import { rtlToggle } from "../../../store/rtlSetting";
import "./style.css";
/* eslint-disable */

function Drawer({ drawer, action }) {
  const [itemSize, setSize] = useState("0px");
  const [item, setItem] = useState("");
  const handler = (e, value) => {
    // e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length;
    if (getItems > 0) {
      if (item !== value) {
        setSize(`${50 * getItems}px`);
        setItem(value);
      } else {
        setItem("");
      }
    }
  };

  const [dataTitleHomeDrawer, setData138] = useState("");
  const [dataTitleAboutDrawer, setData139] = useState("");
  // const [dataTitleServicesDrawer, setData140] = useState("");
  const [dataTitleOurServicesDrawer, setData141] = useState("");
  const [dataTitleServicesDetailsDrawer, setData142] = useState("");
  const [dataTitleProjectsDrawer, setData143] = useState("");
  const [dataTitleProjectsGeneralDrawer, setData144] = useState("");
  // const [dataTitleprojectsDetailsDrawer, setData145] = useState("");
  const [dataTitleTeamDrawer, setData146] = useState("");
  const [dataTitleContactDrawer, setData147] = useState("");
  // const [dataDrawerRrss1, setData148] = useState("");
  // const [dataDrawerRrss2, setData149] = useState("");
  const [dataDrawerEmail, setData150] = useState("");
  const [dataDrawerPhone, setData151] = useState("");
  const [dataDrawerLocation, setData152] = useState("");
  const [dataDrawerEmailLink, setData153] = useState("");
  const [dataDrawerPhoneLink, setData154] = useState("");
  const [dataDrawerLocationLink, setData155] = useState("");
  // const [dataDrawerRrss3, setData156] = useState("");
  const [dataDrawerlanguaje, setData157] = useState("");
  const [dataDrawerES, setData158] = useState("");
  const [dataDrawerEN, setData159] = useState("");

  useEffect(() => {
    consultSeven();
  }, [localStorage.getItem("id_language")]);

  const consultSeven = () => {
    fetch(
      `${
        process.env.REACT_APP_API
      }/ds-pro-mobile-drawers/${localStorage.getItem("id_language")}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData138(data.data.attributes.home_drawer);
        setData139(data.data.attributes.about_drawer);
        // setData140(data.data.attributes.services_drawer);
        setData141(data.data.attributes.ourservices_drawer);
        setData142(data.data.attributes.servicesdetails_drawer);
        setData143(data.data.attributes.projects_drawer);
        setData144(data.data.attributes.projects_general_drawer);
        // setData145(data.data.attributes.projects_details_drawer);
        setData146(data.data.attributes.team_drawer);
        setData147(data.data.attributes.contact_drawer);
        // setData148(data.data.attributes.mobile_drawer_rrss_1);
        // setData149(data.data.attributes.mobile_drawer_rrss_2);
        setData150(data.data.attributes.mobile_drawer_email);
        setData151(data.data.attributes.mobile_drawer_phone);
        setData152(data.data.attributes.mobile_drawer_location);
        setData153(data.data.attributes.mobile_drawer_email_link);
        setData154(data.data.attributes.mobile_drawer_phone_link);
        setData155(data.data.attributes.mobile_drawer_location_link);
        // setData156(data.data.attributes.mobile_drawer_rrss_3);
        setData157(data.data.attributes.navLang);
        setData158(data.data.attributes.navLangEs);
        setData159(data.data.attributes.navLangEn);
      });
  };

  const dispatch = useDispatch();

  return (
    <>
      <div
        onClick={(e) => action(e)}
        className={`off_canvars_overlay ${drawer ? "active" : ""}`}
      ></div>
      <div className="offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`offcanvas_menu_wrapper ${drawer ? "active" : ""}`}
              >
                <a
                  href="/"
                  className="offcanvas-brand text-center mb-50 d-block "
                >
                  <img
                    src={process.env.REACT_APP_API_DRAWER1}
                    alt="Drawer Logo"
                    width="130"
                    height="73"
                  />

                  {/* <img src={process.env.REACT_APP_API_IMAGE} alt="" /> */}
                </a>

                <div id="menu" className="text-left text-white">
                  <ul className="offcanvas_main_menu fontelection">
                    <li
                      onClick={(e) => handler(e, "home")}
                      id="home"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand">
                        <i className=""></i>
                      </span>
                      <a href="/">{dataTitleHomeDrawer}</a>
                      <ul
                        className="sub-menu"
                        style={{
                          height: item === "home" ? itemSize : "0px",
                        }}
                      >
                        {/* <li>
                          <Link to="/">Home 1</Link>
                        </li>
                        <li>
                          <Link to="/home-two">Home 2</Link>
                        </li>
                        <li>
                          <Link to="/home">Home 3</Link>
                        </li>
                        <li>
                          <Link to="/home-four">Home 4</Link>
                        </li>
                        <li>
                          <Link to="/home-five">Home 5</Link>
                        </li>
                        <li>
                          <Link to="/home-six">Home 6</Link>
                        </li>
                        <li>
                          <Link to="/home-seven">Home 7</Link>
                        </li>
                        <li>
                          <Link to="/home-eight">Home 8</Link>
                        </li>
                        <li>
                          <Link to="/home-nine">Home 9</Link>
                        </li> */}
                      </ul>
                    </li>
                    <li
                      onClick={(e) => handler(e, "about")}
                      id="about"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand ">
                        <i className=""></i>
                      </span>
                      <a href="/about">{dataTitleAboutDrawer}</a>
                      <ul
                        className="sub-menu"
                        style={{
                          height: item === "about" ? itemSize : "0px",
                        }}
                      >
                        {/* <li>
                          <Link to="/about-one">About 1</Link>
                        </li>
                        <li>
                          <Link to="/about-two">About 2</Link>
                        </li>
                        <li>
                          <Link to="/about-three">About 3</Link>
                        </li>
                        <li>
                          <a href="/about-four">About four</a>
                        </li>
                        <li>
                          <a href="/about-five">About five</a>
                        </li>
                        <li>
                          <a href="/about-six">About Six</a>
                        </li>
                        <li>
                          <a href="/about-seven">About Seven</a>
                        </li> */}
                      </ul>
                    </li>
                    <li
                      onClick={(e) => handler(e, "service")}
                      id="service"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand ">
                        <i className=""></i>
                      </span>
                      <li>
                        <a href="/service">{dataTitleOurServicesDrawer}</a>
                      </li>
                      {/* <ul
                        className="sub-menu"
                        style={{
                          height: item === "service" ? itemSize : "0px",
                        }}
                      >
                        <li>
                          <a href="/service">{dataTitleOurServicesDrawer} </a>
                        </li>
                        <li>
                          <a href="/service-details">
                            {dataTitleServicesDetailsDrawer}{" "}
                          </a>
                        </li>
                        <li>
                          <a href="/service-three">Service Three</a>
                        </li>
                        <li>
                          <a href="/service-four">Service Four</a>
                        </li>
                        <li>
                          <a href="/service-five">Service five</a>
                        </li>
                        <li>
                          <a href="/service-six">Service Six</a>
                        </li>
                        <li>
                          <a href="/service-seven">Service Seven</a>
                        </li>
                        <li>
                          <a href="/service-details">Service Details</a>
                        </li>
                      </ul> */}
                    </li>
                    <li
                      onClick={(e) => handler(e, "portfolio")}
                      id="portfolio"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand">
                        <i className=""></i>
                      </span>
                      <li>
                        <a href="/portfolio">
                          {dataTitleProjectsGeneralDrawer}
                        </a>
                      </li>
                      {/* <ul
                        className="sub-menu"
                        style={{
                          height: item === "portfolio" ? itemSize : "0px",
                        }}
                      >
                        <li>
                          <a href="/portfolio">
                            {dataTitleProjectsGeneralDrawer}
                          </a>
                        </li>
                        <li>
                          <a href="/portfolio-details">
                            {dataTitleprojectsDetailsDrawer}
                          </a>
                        </li>
                        <li>
                          <a href="/portfolio-three">Portfolio three</a>
                        </li>
                        <li>
                          <a href="/portfolio-four">Portfolio Four</a>
                        </li>
                        <li>
                          <a href="/portfolio-details">Portfolio Details</a>
                        </li>
                      </ul> */}
                    </li>
                    <li
                      onClick={(e) => handler(e, "Pages")}
                      id="Pages"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand">
                        <i className=""></i>
                      </span>
                      <a href="/team">{dataTitleTeamDrawer}</a>
                      <ul
                        className="sub-menu"
                        style={{
                          height: item === "Pages" ? itemSize : "0px",
                        }}
                      >
                        {/* <li>
                          <a href="/counters">Counters</a>
                        </li>
                        <li>
                          <a href="/shop">Shop</a>
                        </li>
                        <li>
                          <a href="/shop-details">Shop details</a>
                        </li>
                        <li>
                          <a href="/pricing">Pricing One</a>
                        </li>
                        <li>
                          <a href="/pricing-two">Pricing Two</a>
                        </li>
                        <li>
                          <a href="/pricing-three">Pricing Three</a>
                        </li>
                        <li>
                          <a href="/pricing-four">Pricing Four</a>
                        </li>
                        <li>
                          <a href="/pricing-five">Pricing Five</a>
                        </li>
                        <li>
                          <a href="/pricing-six">Pricing Six</a>
                        </li>
                        <li>
                          <a href="/team-one">Team One</a>
                        </li>
                        <li>
                          <a href="/team-two">Team Two</a>
                        </li>
                        <li>
                          <a href="/team-three">Team Three</a>
                        </li>
                        <li>
                          <a href="/team-four">Team Four</a>
                        </li>
                        <li>
                          <a href="/team-single">Team Single</a>
                        </li>
                        <li>
                          <a href="/404">404</a>
                        </li> */}
                      </ul>
                    </li>
                    {/* <li
                      onClick={(e) => handler(e, "Blogs")}
                      id="Blogs"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                      </span>
                      <a href="#">Blog</a>
                      <ul
                        className="sub-menu"
                        style={{
                          height: item === "Blogs" ? itemSize : "0px",
                        }}
                      >
                        <li>
                          <Link to="/blog">Blog Page</Link>
                        </li>
                        <li>
                          <Link to="/blog/blog-details">Blog Details</Link>
                        </li>
                      </ul>
                    </li> */}
                    <li
                      onClick={(e) => handler(e, "contact")}
                      id="contact"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand">
                        <i className=""></i>
                      </span>
                      <Link to="/contact">{dataTitleContactDrawer}</Link>
                    </li>
                    <li className="menu-item-has-children active">
                      <div className="language-submenu">
                        <button
                          className="language-button"
                          onClick={() => {
                            if (localStorage.getItem("id_language") != 1) {
                              dispatch(rtlToggle());
                              localStorage.setItem("id_language", 1);
                            }
                          }}
                        >
                          {dataDrawerES}
                        </button>
                        <button
                          className="language-button"
                          onClick={() => {
                            if (localStorage.getItem("id_language") != 2) {
                              dispatch(rtlToggle());
                              localStorage.setItem("id_language", 2);
                            }
                          }}
                        >
                          {dataDrawerEN}
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="offcanvas-social text-white">
                  <ul className="text-center">
                    {/* <li>
                      <a href={dataDrawerRrss1}>
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li> */}
                    {/* <li>
                      <a href={dataDrawerRrss2}>
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li> */}
                    {/* <li>
                      <a href={dataDrawerRrss1}>
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={dataDrawerRrss2}>
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href={dataDrawerRrss3}>
                        <i className="fab fa-whatsapp"></i>
                      </a>
                    </li> */}
                    {/* <li>
                      <a href="$">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="$">
                        <i className="fab fa-dribbble"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="footer-widget-info text-white fontelection">
                  <ul>
                    <li className="marginstops22">
                      <a href={dataDrawerEmailLink}>
                        <i className="fa fa-envelope"></i> {dataDrawerEmail}
                      </a>
                    </li>

                    <li className="marginstops22">
                      <a href={dataDrawerPhoneLink}>
                        <i className="fa fa-phone"></i> {dataDrawerPhone}
                      </a>
                    </li>

                    <li>
                      <a href={dataDrawerLocationLink}>
                        <i className="fa fa-map-marker-alt"></i>{" "}
                        {dataDrawerLocation}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Drawer;
